const { 
  CountryCodes,
  PhoneLengths,
  USAPhoneStart
} = require('./constants');

const defaultCC = '+1';

function _cleanPhoneNumber(phone) {
  if (!phone) return phone;
  // remove + and any other alpha
  phone = phone.replace(/\D/g, '');
  return phone;
}

function _containsCountryCode(value) {
  for (const key in CountryCodes) {
    if (CountryCodes[key] === value) {
      return key.toString();
    }
  }
  return false;
}

function _extractCC1andPhone(phone, userCC, cc1) {
  let allowedPhoneLengths = [10];
  let countryCode = userCC;
  if (phone.substring(1).length === 9 && USAPhoneStart.includes(phone.substring(0, 3))) {
    countryCode = '+1';
  } else {
    if (PhoneLengths[cc1]) {
      allowedPhoneLengths = PhoneLengths[cc1];
    }
    if (allowedPhoneLengths.includes(phone.substring(1).length)) {
      countryCode = '+' + phone.substring(0, 1);
      phone = phone.substring(1);
    }
  }
  return {
    phone,
    countryCode
  };
}

function _extractCC2andPhone(phone, userCC, cc2) {
  let allowedPhoneLengths = [10];
  let countryCode = userCC;
  if (phone.substring(2).length == 8 && USAPhoneStart.includes(phone.substring(0, 3))) {
    countryCode = '+1';
  } else {
    if (PhoneLengths[cc2]) {
      allowedPhoneLengths = PhoneLengths[cc2];
    }
    if (allowedPhoneLengths.includes(phone.substring(2).length)) {
      countryCode = '+' + phone.substring(0, 2);
      phone = phone.substring(2);
    }
  }
  return {
    phone,
    countryCode
  };
}

function _extractCC3andPhone(phone, userCC, cc3) {
  let allowedPhoneLengths = [10];
  let countryCode = userCC;
  // for USA phone as USA is default country
  // for cases such as where USA phone = 2487985645
  // where the first 3 digits correspond to some country's country code
  if (phone.substring(3).length == 7 && USAPhoneStart.includes(phone.substring(0, 3))) {
    countryCode = '+1';
  } else {
    if (PhoneLengths[cc3]) {
      allowedPhoneLengths = PhoneLengths[cc3];
    }
    if (allowedPhoneLengths.includes(phone.substring(3).length)) {
      countryCode = '+' + phone.substring(0, 3);
      phone = phone.substring(3);
    }
  }
  return {
    phone,
    countryCode
  };
}

function _checkCCAndGetFullPhone(phone, userCC, cc1, cc2, cc3) {
  const countryCode = userCC;
  const phoneNumberObject = {
    phone,
    countryCode
  };
  let extractedphone = {};
  if (cc1) {
    extractedphone = _extractCC1andPhone(phone, userCC, cc1);
    phoneNumberObject.phone = extractedphone.phone;
    phoneNumberObject.countryCode = extractedphone.countryCode;
  } else if (cc2) {
    extractedphone = _extractCC2andPhone(phone, userCC, cc2);
    phoneNumberObject.phone = extractedphone.phone;
    phoneNumberObject.countryCode = extractedphone.countryCode;
  } else if (cc3) {
    extractedphone = _extractCC3andPhone(phone, userCC, cc3);
    phoneNumberObject.phone = extractedphone.phone;
    phoneNumberObject.countryCode = extractedphone.countryCode;
  }
  return phoneNumberObject;
}

function normalizePhone(rawPhone) {
  const cleanPhone = _cleanPhoneNumber(rawPhone);
  const cc1 = _containsCountryCode(cleanPhone.substring(0, 1));
  const cc2 = _containsCountryCode(cleanPhone.substring(0, 2));
  const cc3 = _containsCountryCode(cleanPhone.substring(0, 3));

  const fullPhoneNumberObject = _checkCCAndGetFullPhone(cleanPhone, defaultCC, cc1, cc2, cc3);
  fullPhoneNumberObject.countryCode = fullPhoneNumberObject.countryCode || defaultCC;

  return fullPhoneNumberObject;
}

module.exports.normalizePhone = normalizePhone;

