module.exports = {
  CountryCodes: {
    US: '1',
    AU: '61',
    CN: '86',
    IN: '91',
    BR: '55',
    ID: '62',
    JP: '81',
    RU: '7',
    NG: '234',
    DE: '49',
    MX: '52',
    BD: '880',
    GB: '44',
    IR: '98',
    FR: '33',
    PH: '63',
    IT: '39',
    KR: '82',
    BE: '32',
    BF: '226',
    BG: '359',
    BA: '387',
    BB: '+1-246',
    WF: '681',
    BL: '590',
    BM: '+1-441',
    BN: '673',
    BO: '591',
    BH: '973',
    BI: '257',
    BJ: '229',
    BT: '975',
    JM: '+1-876',
    BV: '',
    BW: '267',
    WS: '685',
    BQ: '599',
    BS: '+1-242',
    JE: '+44-1534',
    BY: '375',
    BZ: '501',
    RW: '250',
    RS: '381',
    TL: '670',
    RE: '262',
    TM: '993',
    TJ: '992',
    RO: '40',
    TK: '690',
    GW: '245',
    GU: '+1-671',
    GT: '502',
    GS: '',
    GR: '30',
    GQ: '240',
    GP: '590',
    GY: '592',
    GG: '+44-1481',
    GF: '594',
    GE: '995',
    GD: '+1-473',
    GA: '241',
    SV: '503',
    GN: '224',
    GM: '220',
    GL: '299',
    GI: '350',
    GH: '233',
    OM: '968',
    TN: '216',
    JO: '962',
    HR: '385',
    HT: '509',
    HU: '36',
    HK: '852',
    HN: '504',
    HM: ' ',
    VE: '58',
    PR: '+1-787 and 1-939',
    PS: '970',
    PW: '680',
    PT: '351',
    SJ: '47',
    PY: '595',
    IQ: '964',
    PA: '507',
    PF: '689',
    PG: '675',
    PE: '51',
    PK: '92',
    PN: '870',
    PL: '48',
    PM: '508',
    ZM: '260',
    EH: '212',
    EE: '372',
    EG: '20',
    ZA: '27',
    EC: '593',
    VN: '84',
    SB: '677',
    ET: '251',
    SO: '252',
    ZW: '263',
    SA: '966',
    ES: '34',
    ER: '291',
    ME: '382',
    MD: '373',
    MG: '261',
    MF: '590',
    MA: '212',
    MC: '377',
    UZ: '998',
    MM: '95',
    ML: '223',
    MO: '853',
    MN: '976',
    MH: '692',
    MK: '389',
    MU: '230',
    MT: '356',
    MW: '265',
    MV: '960',
    MQ: '596',
    MP: '+1-670',
    MS: '+1-664',
    MR: '222',
    IM: '+44-1624',
    UG: '256',
    TZ: '255',
    MY: '60',
    IL: '972',
    IO: '246',
    SH: '290',
    FI: '358',
    FJ: '679',
    FK: '500',
    FM: '691',
    FO: '298',
    NI: '505',
    NL: '31',
    NO: '47',
    NA: '264',
    VU: '678',
    NC: '687',
    NE: '227',
    NF: '672',
    NZ: '64',
    NP: '977',
    NR: '674',
    NU: '683',
    CK: '682',
    XK: '',
    CI: '225',
    CH: '41',
    CO: '57',
    CM: '237',
    CL: '56',
    CA: '1',
    CG: '242',
    CF: '236',
    CD: '243',
    CZ: '420',
    CY: '357',
    CR: '506',
    CW: '599',
    CV: '238',
    CU: '53',
    SZ: '268',
    SY: '963',
    SX: '599',
    KG: '996',
    KE: '254',
    SS: '211',
    SR: '597',
    KI: '686',
    KH: '855',
    KN: '+1-869',
    KM: '269',
    ST: '239',
    SK: '421',
    SI: '386',
    KP: '850',
    KW: '965',
    SN: '221',
    SM: '378',
    SL: '232',
    SC: '248',
    KZ: '7',
    KY: '+1-345',
    SG: '65',
    SE: '46',
    SD: '249',
    DO: '+1-809 and 1-829',
    DM: '+1-767',
    DJ: '253',
    DK: '45',
    VG: '+1-284',
    YE: '967',
    DZ: '213',
    UY: '598',
    YT: '262',
    LB: '961',
    LC: '+1-758',
    LA: '856',
    TV: '688',
    TW: '886',
    TT: '+1-868',
    TR: '90',
    LK: '94',
    LI: '423',
    LV: '371',
    TO: '676',
    LT: '370',
    LU: '352',
    LR: '231',
    LS: '266',
    TH: '66',
    TF: '',
    TG: '228',
    TD: '235',
    TC: '+1-649',
    LY: '218',
    VA: '379',
    VC: '+1-784',
    AE: '971',
    AD: '376',
    AG: '+1-268',
    AF: '93',
    AI: '+1-264',
    VI: '+1-340',
    IS: '354',
    AM: '374',
    AL: '355',
    AO: '244',
    AQ: '',
    AS: '+1-684',
    AR: '54',
    AT: '43',
    AW: '297',
    AX: '+358-18',
    AZ: '994',
    IE: '353',
    UA: '380',
    QA: '974',
    MZ: '258'
  },

  PhoneLengths: {
    US: [10],
    AW: [7],
    AF: [9],
    AO: [9],
    AI: [10],
    AX: [6, 7, 8],
    AL: [9],
    AD: [6],
    AE: [9, 10],
    AR: [6, 7, 8, 9],
    AM: [8],
    AS: [10],
    AG: [10],
    AU: [9, 10],
    AT: [10, 11, 12, 13, 14],
    AZ: [9],
    BI: [8],
    BE: [9],
    BJ: [8],
    BF: [8],
    BD: [8, 9, 10],
    BG: [8, 9],
    BH: [8],
    BS: [10],
    BA: [8],
    BY: [9],
    BZ: [7],
    BM: [10],
    BO: [8],
    BR: [10, 11, 12],
    BB: [10],
    BN: [7],
    BT: [8],
    BW: [8],
    CF: [8],
    CA: [10],
    CH: [9],
    CL: [9],
    CN: [11],
    CI: [8],
    CM: [8],
    CD: [9],
    CG: [9],
    CK: [5],
    CO: [10],
    KM: [7],
    CV: [7],
    CR: [8],
    CU: [8],
    KY: [10],
    CY: [8],
    CZ: [9],
    DE: [10, 11, 12],
    DJ: [8],
    DM: [10],
    DK: [8],
    DO: [10],
    DZ: [9],
    EC: [9],
    EG: [10],
    ER: [7],
    ES: [9],
    EE: [7, 8],
    ET: [9],
    FI: [9, 10],
    FJ: [7],
    FK: [5],
    FR: [9],
    FO: [6],
    FM: [7],
    GA: [8],
    GB: [10, 11],
    GE: [9],
    GH: [9, 10],
    GI: [8],
    GN: [8],
    GP: [9],
    GM: [7],
    GW: [7],
    GQ: [9],
    GR: [10],
    GD: [10],
    GL: [6],
    GT: [8],
    GF: [9],
    GU: [10],
    GY: [7],
    HK: [8],
    HN: [8],
    HR: [8, 9],
    HT: [8],
    HU: [9],
    ID: [9, 10, 11, 12],
    IN: [10],
    IE: [9],
    IR: [10],
    IQ: [10],
    IS: [7],
    IL: [9],
    IT: [10],
    JM: [10],
    JO: [9],
    JP: [10],
    KZ: [10],
    KE: [9, 10],
    KG: [9],
    KH: [8, 9],
    KI: [5],
    KN: [10],
    KR: [9, 10],
    KW: [8],
    LA: [10],
    LB: [7, 8],
    LR: [7, 8],
    LY: [9],
    LC: [10],
    LI: [7],
    LK: [9],
    LS: [8],
    LT: [8],
    LU: [9],
    LV: [8],
    MO: [8],
    MA: [9],
    MC: [8, 9],
    MD: [8],
    MG: [9],
    MV: [7],
    MX: [10, 11],
    MH: [7],
    MK: [8],
    ML: [8],
    MT: [8],
    MM: [8],
    ME: [8],
    MN: [8],
    MP: [10],
    MZ: [9],
    MR: [8],
    MS: [10],
    MQ: [9],
    MU: [7],
    MW: [9],
    MY: [9, 10],
    YT: [9],
    NA: [9],
    NC: [6],
    NE: [8],
    NF: [5],
    NG: [10, 11],
    NI: [8],
    NU: [4],
    NL: [9, 10],
    NO: [8],
    NP: [10],
    NR: [7],
    NZ: [8, 9, 10],
    OM: [8],
    PK: [10, 11],
    PA: [8],
    PE: [9],
    PH: [10 ,11],
    PW: [7],
    PG: [8],
    PL: [9],
    PR: [10],
    PT: [9],
    PY: [9],
    PS: [9],
    PF: [6],
    QA: [8],
    RE: [9],
    RO: [9],
    RU: [10],
    RW: [9],
    SA: [9, 10],
    SD: [9],
    SN: [9],
    SG: [8],
    SH: [4],
    SJ: [8],
    SB: [7],
    SL: [8],
    SV: [8],
    SM: [10],
    SO: [8],
    SX: [10],
    PM: [6],
    RS: [8, 9],
    ST: [7],
    SR: [7],
    SK: [9],
    SI: [8],
    SE: [9],
    SC: [7],
    SY: [9],
    TC: [10],
    TD: [8],
    TG: [8],
    TH: [9],
    TJ: [9],
    TK: [4],
    TM: [8],
    TL: [8],
    TO: [5],
    TT: [10],
    TN: [8],
    TR: [10],
    TV: [5],
    TW: [9],
    TZ: [9],
    UG: [9],
    UA: [9],
    UY: [8],
    UZ: [9],
    VC: [10],
    VE: [10],
    VG: [10],
    VI: [10],
    VN: [9, 10],
    VU: [7],
    WF: [6],
    WS: [7],
    YE: [9],
    ZA: [9, 10],
    ZM: [9],
    ZW: [9]
  },

  USAPhoneStart: [
    '201',
    '202',
    '203',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '224',
    '225',
    '227',
    '228',
    '229',
    '231',
    '234',
    '239',
    '240',
    '248',
    '251',
    '252',
    '253',
    '254',
    '256',
    '260',
    '262',
    '267',
    '269',
    '270',
    '272',
    '274',
    '276',
    '278',
    '281',
    '283',
    '301',
    '302',
    '303',
    '304',
    '305',
    '307',
    '308',
    '309',
    '310',
    '312',
    '313',
    '314',
    '315',
    '316',
    '317',
    '318',
    '319',
    '320',
    '321',
    '323',
    '325',
    '327',
    '330',
    '331',
    '334',
    '336',
    '337',
    '339',
    '341',
    '346',
    '347',
    '351',
    '352',
    '360',
    '361',
    '364',
    '369',
    '380',
    '385',
    '386',
    '401',
    '402',
    '404',
    '405',
    '406',
    '407',
    '408',
    '409',
    '410',
    '412',
    '413',
    '414',
    '415',
    '417',
    '419',
    '423',
    '424',
    '425',
    '430',
    '432',
    '434',
    '435',
    '440',
    '442',
    '443',
    '445',
    '447',
    '458',
    '464',
    '469',
    '470',
    '475',
    '478',
    '479',
    '480',
    '484',
    '501',
    '502',
    '503',
    '504',
    '505',
    '507',
    '508',
    '509',
    '510',
    '512',
    '513',
    '515',
    '516',
    '517',
    '518',
    '520',
    '530',
    '531',
    '534',
    '539',
    '540',
    '541',
    '551',
    '557',
    '559',
    '561',
    '562',
    '563',
    '564',
    '567',
    '570',
    '571',
    '573',
    '574',
    '575',
    '580',
    '582',
    '585',
    '586',
    '601',
    '602',
    '603',
    '605',
    '606',
    '607',
    '608',
    '609',
    '610',
    '612',
    '614',
    '615',
    '616',
    '617',
    '618',
    '619',
    '620',
    '623',
    '626',
    '627',
    '628',
    '629',
    '630',
    '631',
    '636',
    '641',
    '646',
    '650',
    '651',
    '657',
    '659',
    '660',
    '661',
    '662',
    '667',
    '669',
    '678',
    '679',
    '681',
    '682',
    '689',
    '701',
    '702',
    '703',
    '704',
    '706',
    '707',
    '708',
    '712',
    '713',
    '714',
    '715',
    '716',
    '717',
    '718',
    '719',
    '720',
    '724',
    '725',
    '727',
    '730',
    '731',
    '732',
    '734',
    '737',
    '740',
    '747',
    '752',
    '754',
    '757',
    '760',
    '762',
    '763',
    '764',
    '765',
    '769',
    '770',
    '772',
    '773',
    '774',
    '775',
    '779',
    '781',
    '785',
    '786',
    '801',
    '802',
    '803',
    '804',
    '805',
    '806',
    '808',
    '810',
    '812',
    '813',
    '814',
    '815',
    '816',
    '817',
    '818',
    '828',
    '830',
    '831',
    '832',
    '835',
    '843',
    '845',
    '847',
    '848',
    '850',
    '854',
    '856',
    '857',
    '858',
    '859',
    '860',
    '862',
    '863',
    '864',
    '865',
    '870',
    '872',
    '878',
    '901',
    '903',
    '904',
    '906',
    '907',
    '908',
    '909',
    '910',
    '912',
    '913',
    '914',
    '915',
    '916',
    '917',
    '918',
    '919',
    '920',
    '925',
    '927',
    '928',
    '929',
    '931',
    '934',
    '935',
    '936',
    '937',
    '938',
    '940',
    '941',
    '947',
    '949',
    '951',
    '952',
    '954',
    '956',
    '957',
    '959',
    '970',
    '971',
    '972',
    '973',
    '975',
    '978',
    '979',
    '980',
    '984',
    '985',
    '989'
  ]
}